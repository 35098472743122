CustomEase.create("ease", "M0,0,C0.5,0,0,1,1,1")
gsap.to('.logo', {
    scale: 1,
    rotate: 360,
    opacity: 1,
    ease: "expo.inOut",
    duration: 2,
})
gsap.to('.navigation', {
    opacity: 1,
    delay: 1.5,
    ease: "expo.inOut",
    duration: 1,
})
gsap.to('.construct', {
    opacity: 1,
    delay: 1,
    ease: "expo.inOut",
    duration: 1,
})
gsap.to('.title', {
    opacity: 1,
    delay: 1,
    ease: "expo.inOut",
    duration: 1,
})
gsap.to('.contact', {
    opacity: 1,
    delay: 1.5,
    ease: "expo.inOut",
    duration: 1,
})
gsap.to('.thumbnail', {
    opacity: 1,
    delay: 2,
    ease: "expo.inOut",
    duration: 1,
})

class Slider {
    constructor() {
        this.bind()
        this.container = document.querySelector('.thumbnail')
        this.img = this.container.querySelector('img')
        this.navigation = document.querySelector('nav.navigation')
        this.item = [...this.navigation.querySelectorAll('li')]
        this.imgs = [...this.navigation.querySelectorAll('img')]

        this.index = 0

        this.active = false
        this.rAF = undefined
        this.init()
    }
    bind() {
        ['hover','addEvents','autoChange','addActive'].forEach((fn) => this[fn] = this[fn].bind(this))
    }
    autoChange(){
        setInterval(()=>{
            if(this.active === true) return
            this.index === 2 ? this.index = 0 :  this.index++
            this.addActive()
            this.changeImg()
        }, 5000)
    }
    addActive(){
        this.item.forEach((el)=>{
            el.classList.remove('active')
        })
        this.item[this.index].classList.add('active')
    }
    changeImg(){
        gsap.to(this.container, {
            opacity: 0,
            scale: 1,
            ease:  "circ.out",
            duration: .5
        })
        setTimeout(()=>{
            gsap.fromTo(this.container, {
                opacity: 0,
                scale: 1.1,
                ease:  "circ.out",
                duration: .5
            }, {
                opacity: 1,
                scale: 1,
                ease:  "circ.out",
                duration: .5
            })
            this.img.src = this.imgs[this.index].src
        }, 0)
    }
    hover(e, el, event){
        if(event === 'enter'){
            this.navigation.classList.add('active')
            this.active = true
        }else{
            this.navigation.classList.remove('active')
            this.active = false
        }
        if(parseInt(el.getAttribute('data-slide')) === this.index) return
        this.index = parseInt(el.getAttribute('data-slide'))
        this.addActive()
        this.changeImg()
    }
    addEvents() {
        this.item.forEach( el =>{
            el.addEventListener('mouseenter', e => { this.hover(e, el, 'enter') })
            el.addEventListener('mouseleave', e => { this.hover(e, el, 'leave') })
        })
    }
    init() {
        this.addActive()
        this.changeImg()
        this.autoChange()
        this.addEvents()
    }
}

setTimeout(()=>{
    new Slider()
}, 1500)
